<template>
<div>
  <div class="full-width category-container">
    <div class="container">
      <div class="col-12 category-filters">

        <center v-if="loading">
          <h1 v-if="!pending">Stiamo elaborando il tuo ordine: non chiudere il browser e attendi il messaggio di conferma ordine che apparirà a breve.</h1>
          <h1 v-if="pending">Siamo in attesa della conferma del pagamento da Satispay, riceverai una notifica via e-mail al completamento dell'ordine.</h1>
        </center>

        <center v-if="!loading && !error">
          <h1>GRAZIE! Il tuo ordine di importo {{amount}}€ è stato completato, a breve riceverai una mail di conferma.</h1>
        </center>

        <center v-if="!loading && error">
          <h3>Qualcosa è andato storto con la tua richiesta di pagamento, riprova più tardi</h3>
          <br><br>
          <router-link class="button" to="/cart">Ripeti il pagamento</router-link>
        </center>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import queryString from 'query-string'
import axios from 'axios'

export default {
  name: 'Thankyou',
  data: function () {
    return {
      loading: true,
      pending: false,
      error: false,
      amount: 0
    }
  },

  async mounted () {
    let queryData = queryString.parse(location.search)

    if(queryData.id) {
      this.amount = await this.getAmount(queryData.id)
      let complete = await this.checkComplete(queryData.id)

      if (![4,1].includes(complete) ) {
        await this.checkPayment(queryData.id)
      } else {
        this.$store.commit('emptyCart')
        this.$store.commit('setDonation', 0)
        this.loading = false
      }
    }
  },

  methods: {
    async getAmount (id) {
      let resp = false
      if("order_total" in window) return window.order_total.replace('.', ',')
      try {
        resp = await axios.post(this.$config.backendUrl + 'index.php?action=get_order_amount&id=' + id, "{}")
      } catch (e) {
        return -1
      }
      return resp.data
    },
    async checkComplete (id) {
      let resp = false
      try {
        resp = await axios.post(this.$config.backendUrl + 'index.php?action=get_order_status&id=' + id, "{}")
      } catch (e) {
        return -1
      }

      return resp.data
    },
    async checkPayment (id) {
      try {
        let resp = await axios.post(this.$config.backendUrl + 'index.php?action=get_payment_status&id=' + id, "{}")
        if(resp.data.status == 'PENDING') {
          this.pending = true
          setTimeout(() => {
            window.location.reload()
          }, 5000);
        } else if (resp.data.status != 'OK') {
          await axios.get(this.$config.backendUrl + 'index.php?action=set_order_status&status=3&id=' + id)
          this.error = true
          this.loading = false
        } else {
          this.complete(id)
        }

      } catch (e) {
        this.$toast.open('Qualcosa è andato storto')
      }
    },
    async complete (id) {
      try {
        await axios.post(this.$config.backendUrl + 'index.php?action=complete&id=' + id, "{}")
        await axios.get(this.$config.backendUrl + 'index.php?action=set_order_status&status=4&id=' + id)
        await axios.post(this.$config.backendUrl + 'index.php?action=invioGiftCheckout', JSON.stringify({
          gifts: this.$store.state.cart.filter((item) => item.gift),
          nome_sender: this.$store.state.user.data.name,
          cognome_sender: this.$store.state.user.data.surname
        }));

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "checkoutOption",
          ecommerce: {
            checkout_option: {
              actionField: {'step': 3, 'option': 'Order completed'},
            }
          }
        })

        let gtmItems = []
        let fbqItems = []
        let subTotal = 0
        let num_items = 0

        this.$store.state.cart.forEach((item) => {
          subTotal += this.$utils.getProductPrice(item.item) * item.qty
          num_items += item.qty
          gtmItems.push({
            item_name: item.item.productName,
            item_id: item.item.itemNumber,
            item_brand: item.item.brand,
            item_category: item.item.categories[item.item.categories.length - 1].categoryName,
            item_variant: item.item.fairTradeSuppliers ? item.item.fairTradeSuppliers[0] : '',
            name: item.item.productName,
            id: item.item.itemNumber,
            brand: item.item.brand,
            category: item.item.categories[item.item.categories.length - 1].categoryName,
            variant: item.item.fairTradeSuppliers ? item.item.fairTradeSuppliers[0] : '',
            price: item.item.productPrice,
            quantity: item.qty
          })
          fbqItems.push({
            id: item.item.itemNumber,
            quantity: item.qty,
            item_price: item.item.productPrice
          })
        })

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            purchase: {
              actionField: {
                id: id,
                affiliation: "Online Store",
                revenue: subTotal,
                tax: "0",
                shipping: 7,
                currency: "EUR",
                coupon: ""
              },
              products: gtmItems
            },
            transaction_id: id,
            affiliation: "Online Store",
            value: subTotal,
            tax: 0,
            shipping: 7,
            currency: "EUR",
            coupon: "",
            items: gtmItems
          }
        });

        if("fbq" in window) {
          window.fbq('track', 'Purchase', {
            content_type: "product",
            contents: fbqItems,
            currency: "EUR",
            num_items: num_items,
            value: subTotal,
          });
        }

        this.$store.commit('emptyCart')
        this.$store.commit('setDonation', 0)
        this.loading = false
      } catch (e) {
        this.$toast.open('Qualcosa è andato storto')
      }
    }
  }
}
</script>

<style scoped>
.category-container {
  margin-top: 38px;
  padding-bottom: 60px;
}

.category-content h2 {
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin: 0 0 20px 0;
}

.category-content p {
  margin: 0 0 25px 0;
  max-width: 66.66666%;
  line-height: 140%;
  font-size: 14px;
  font-weight: 500;
}

.single-filter {
  width: 100%;
  padding-right: 50px;
}

.single-filter .filter-name {
  display: block;
  font-weight: 800;
  font-size: 13px;
  margin-bottom: 20px;
  text-transform: uppercase;
  position: relative;
  height: 25px;
  line-height: 25px;
  margin-top: 0;
  cursor: pointer;
  letter-spacing: 0.5px;
}

.single-filter .filter-name:after {
  content: '';
  position: absolute;
  top: -1px;
  right: 0;
}

.single-filter .filter-content {
  padding-left: 40px;
  margin-bottom: 40px;
}

.single-filter .filter-content a {
  display: block;
  font-weight: 500;
  font-weight: 13px;
  margin-bottom: 12px;
}
</style>
